import React, { Fragment } from 'react';
import Header from '@components/Header';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { css } from '@emotion/react';
import Footer from '@components/Footer';
import { has } from 'lodash';
import BackgroundImage from 'gatsby-background-image';
import FrontpageLink from '@components/FrontpageLink';
import { createUtmQuery } from '@lib/searchQuery';
import ResponsiveVideo from '@components/ui/ResponsiveVideo';
import PageTitle from '@components/ui/PageTitle';
import CtaButton from '@components/ui/CtaButton';
import Hero from '@components/theme/Hero';
import { getFirstBlock } from '@lib/blocks';

const utmMedium = 'videos';

export default function VideosTemplate({ data: { page }, pageContext: { language, refs, layouts, configuration }}) {
  const { title, link, videos } = getFirstBlock(page);
  return (
    <Fragment>
      <Hero title={title} divider={{ variant: 4, reflect: true }}>
        <Header
          language={language}
          refs={refs}
          layouts={layouts}
        />
      </Hero>
      <div className="container py-5">
        <div className="row">
          {videos.map(({ title, videoId, description }, i) =>
            <div className="col" key={videoId}>
              <ResponsiveVideo title={title} videoId={videoId} />
              <h2 className="text-center mt-3">{description}</h2>
            </div>
          )}
        </div>
        <CtaButton
          link={link}
          utmMedium={utmMedium}
          utmContent="cta_button_benefits"
        />
      </div>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Fragment>
  );
}

export const query = graphql`
  query($videosId: String) {
    page: wpCustomPage(id: { eq: $videosId }) {
      language {
        code
      }
      block {
        block {
          ... on WpCustomPage_Block_Block_VideosSection {
            title
            link {
              linkText
              linkUrl
              linkStyle
            }
            videos {
              title
              videoId
              description
              type
            }
          }
        }
      }
    }
  }
`;